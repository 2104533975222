//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: "login",
	data() {
		return {
			// loginForm: {
			// 	username: "demongo",
			// 	password: "password",
			// },
			loginForm: {
				username: "",
				password: "",
			},
		};
	},
	methods: {
		async loginUser() {
			if (this.loginForm.username && this.loginForm.password) {
				await this.$store.dispatch(`auth/login`, this.loginForm);
			}
		},
	},
	created() {
		this.$store.commit("auth/SET_ERROR_MESSAGE", "");
	},
	head() {
		return {
			title: `${this.$t(`login`)} – VoteLog`,
		};
	},
};
